import { zPermissionApi, zLicenceApi, zBffApi } from '@/api';
import { createCommandService } from '@/services';

const getApplicationsUser = async ({ userId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/usuario/${userId}/aplicacao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getApplicationsLoggedUser = async ({ onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/usuario/aplicacao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getCompaniesLoggedUser = async ({ query, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: query
      ? `${zLicenceApi}/cliente/paged?page=${query.page - 1}&size=${query.size}`
      : `${zLicenceApi}/cliente`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const reloadUserPermissions = async ({ applicationKey }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${applicationKey}/usuario-perfil/carregar/permissoes`,
  });

const getUserData = async ({ userId, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${zBffApi}/dados-usuario/${userId}`,
    onSuccess,
    onCustomError,
  });

export {
  getApplicationsUser,
  getApplicationsLoggedUser,
  getCompaniesLoggedUser,
  reloadUserPermissions,
  getUserData,
};
