import Vue from 'vue';
import {
  getApplicationsLoggedUser,
  getApplicationsUser,
  getCompaniesLoggedUser,
  reloadUserPermissions,
  getUserData,
} from '@/services/user';

const loadUserApplications = async ({ commit }, { userId }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: true,
  });

  await getApplicationsUser({
    userId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'userApplications',
        payload: data.map((item) => item.key),
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações do usuário');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoading',
        payload: true,
      });
    },
  });
};

const loadLoggedUserApplications = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: true,
  });

  await getApplicationsLoggedUser({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'userApplications',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações do usuário');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoading',
        payload: true,
      });
    },
  });
};

const loadLoggedUserClients = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoadingClients',
    value: true,
  });
  await getCompaniesLoggedUser({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'clients',
        value: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(
        message ?? 'Ocorreu um erro de requisição ao atualizar client no token de usuário',
      );
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoadingClients',
        value: false,
      });
    },
  });
};

const reloadPermissions = async ({ commit }, { applicationKey }) => {
  commit('GENERIC_MUTATION', { property: 'isLoadingPermissions', value: true });

  await reloadUserPermissions({ applicationKey });

  commit('GENERIC_MUTATION', { property: 'isLoadingPermissions', value: false });
};

const changeShowSessionDialog = ({ commit }, { value }) =>
  commit('GENERIC_MUTATION', { property: 'showSessionDialog', payload: value });

const loadLoggedUserData = async ({ commit }, userId) => {
  await getUserData({
    userId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'userData',
        payload: data?.usuario,
      });
    },
    onCustomError: ({ response }) => {
      Vue.$toast.error(response?.data?.trace?.title);
    },
  });
};

export default {
  loadUserApplications,
  loadLoggedUserApplications,
  loadLoggedUserClients,
  reloadPermissions,
  changeShowSessionDialog,
  loadLoggedUserData,
};
