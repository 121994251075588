import Vue from 'vue';
import VueRouter from 'vue-router';
import { configureBeforeEachRouter } from '@zitrus/utils-service';

Vue.use(VueRouter);

const routes = [
  {
    path: '/**',
    name: 'default',
    component: () => import('@/views/z-header.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => configureBeforeEachRouter(router, to, from, next));

export default router;
