import { getUserAppsByClient } from '@/services/client';
import Vue from 'vue';
import { formatUserApps } from '@/utils/clientApplicationsFormat';

const loadClientApplications = async ({ commit }, { clientId }) => {
  await getUserAppsByClient({
    clientId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'clientApplications',
        payload: formatUserApps(data),
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações do cliente');
    },
  });
};

export default { loadClientApplications };
