import { createCommandService } from '@/services';
import { notificacoesURI } from '@/api';

const ressus = {
  getCounter: ({ onSuccess }) =>
    createCommandService({
      method: 'get',
      uri: `${notificacoesURI}/Ressus/quantidade_nao_lidas_totais`,
      onSuccess,
    }),
};

export { ressus };
