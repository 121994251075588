import { createCommandService } from '@/services/index';
import { zBffApi } from '@/api';

const getUserAppsByClient = async ({ clientId, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${zBffApi}/usuario/aplicacao/cliente/${clientId}`,
    onSuccess,
    onCustomError,
  });

export { getUserAppsByClient };
