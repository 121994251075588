const ressus = require('@/assets/logo-ressus.svg');
const gpl = require('@/assets/logo-gpl.svg');
const workspace = require('@/assets/logo-workspace.svg');

const logos = {
  ressus,
  gpl,
  workspace,
};

export default logos;
