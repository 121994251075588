const notificacoesURI =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_NOTIFICACOES
    : process.env.NOTIFICACOES_URL;

const notificacoesWebsocketURI =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_NOTIFICACOES_WEBSOCKET
    : process.env.NOTIFICACOES_WEBSOCKET_URL;

const graphQLServiceNotificacoesURI = `${notificacoesURI}/graphql`;

const zPermissionApi =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_ZPERMISSION
    : process.env.ZPERMISSION_URL;

const zLicenceApi =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ZLICENCE : process.env.ZLICENCE_URL;

const zBffApi =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ZBFF : process.env.ZBFF_URL;

export {
  notificacoesURI,
  notificacoesWebsocketURI,
  graphQLServiceNotificacoesURI,
  zPermissionApi,
  zLicenceApi,
  zBffApi,
};
