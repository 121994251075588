<template>
  <v-snackbar
    v-model="options.show"
    absolute
    top
    right
    transition="scroll-x-transition"
    :content-class="contentClass"
  >
    <div class="flex items-center justify-between gap-3 p-1">
      <div class="flex items-center gap-3">
        <v-icon>{{ options.icon }}</v-icon>
        <div class="text-base">
          <span v-html="options.message" />
          <router-link v-if="options.to" class="ml-1 text-primary" :to="options.to">
            <span class="underline">clique aqui.</span>
          </router-link>
        </div>
      </div>
      <v-icon @click="options.show = false">mdi-close</v-icon>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ZToast',
  computed: {
    options() {
      return this.$toast.options;
    },
    contentClass() {
      const types = {
        success: 'rounded-lg bg-feedback-green-low',
        error: 'rounded-lg bg-feedback-orange-low',
        info: 'rounded-lg bg-feedback-blue-low',
        warning: 'rounded-lg bg-feedback-yellow-low',
      };

      return types[this.$toast.options.type];
    },
  },
};
</script>

<style scoped lang="scss">
.v-snack--absolute {
  top: 112px;
  right: 16px;
}

:deep(.v-snack__wrapper) {
  background-color: transparent !important;
  box-shadow: none !important;
  color: theme('colors.primary-blue-base') !important;

  i,
  button {
    color: theme('colors.primary-blue-base') !important;
  }
}
</style>
