const applicationsAvailable =
  () =>
  (actualApplicationKey = '') => {
    const apps = [
      {
        name: 'SGU Ressus',
        key: 'ressus',
      },
      {
        name: 'Workspace',
        key: 'workspace',
      },
    ];

    if (actualApplicationKey && actualApplicationKey.length > 0) {
      return apps.filter((item) => item.key !== actualApplicationKey);
    }

    return apps;
  };

const clientsAvailable =
  ({ userClients }) =>
  (actualClientId = null) => {
    if (actualClientId && actualClientId > 0) {
      return userClients.filter((client) => client.id !== Number(actualClientId));
    }

    return userClients;
  };

export default { applicationsAvailable, clientsAvailable };
