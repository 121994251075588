<template>
  <v-dialog
    v-model="showSessionDialog"
    persistent
    content-class="session-dialog"
    width="700"
    :key="dialogKey"
  >
    <v-card flat>
      <v-card-title>
        <v-spacer />
        <v-icon data-test="icon-close" @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-icon dark class="mb-3" size="50px">mdi-information-outline</v-icon>
        <h1 class="mb-2 font-bold text-xl">Houve alteração no seu acesso</h1>
        <span class="text-sm">
          Você trocou de empresa ou fez login com outra conta.<br />
          Neste caso, as abas são atualizadas para a sessão atual.
        </span>
      </v-card-text>
      <v-card-actions>
        <v-layout :justify-end="true">
          <v-progress-circular v-if="isLoading" indeterminate />
          <v-btn
            v-if="!isLoading"
            dark
            class="text-capitalize ml-2 btn btn__primary"
            data-test="btn-aceitar"
            @click="acceptedReload"
          >
            Aceitar
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SessionChangeDialog',
  data() {
    return {
      dialogKey: 0,
      isLoading: false,
      clientPath: '/workspace/selecionar-empresa',
      appPath: '/workspace/selecionar-aplicacao',
      query: { hideHeader: true, hideMenu: true },
    };
  },
  computed: {
    ...mapState('user', ['showSessionDialog']),
  },
  watch: {
    showSessionDialog() {
      this.dialogKey += 1;
    },
  },
  methods: {
    ...mapActions('user', ['changeShowSessionDialog']),
    close() {
      this.changeShowSessionDialog({ value: false });
    },
    applicationKey() {
      const FIRST_PATH = 0;
      const pathArray = this.$route.path.split('/');

      pathArray.shift();

      return pathArray[FIRST_PATH];
    },
    acceptedReload() {
      this.close();

      this.$router.push({
        path: this.getLoggedUser()?.clientId ? this.appPath : this.clientPath,
        query: this.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.session-dialog {
  .v-card {
    .v-icon {
      color: theme('colors.primary-blue-base') !important;
      width: fit-content;
    }

    > .v-card__title {
      border-bottom: thin solid theme('colors.primary-blue-base') !important;
    }

    > .v-card__text {
      padding: 32px 32px 32px 32px !important;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    > .v-card__actions {
      border-top: thin solid theme('colors.primary-blue-base') !important;
    }

    &__title,
    &__text {
      color: theme('colors.primary-blue-base') !important;
    }
  }
}
</style>
