export default () => ({
  isLoading: false,
  isLoadingPermissions: false,
  userApplications: [],
  userClients: [],
  showSessionDialog: false,
  userData: {
    active: null,
    createdAt: null,
    email: null,
    expiredPassword: null,
    id: null,
    login: null,
    name: null,
    picture: null,
    responsibleClient: null,
    status: null,
    userName: null,
  },
});
