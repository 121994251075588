import { ressus } from '@/services/get-counter';

const counters = { ressus };

const loadCounter = async ({ commit }, { payload, applicationKey }) => {
  if (payload) {
    commit('GENERIC_MUTATION', {
      property: 'counter',
      payload: payload[0]?.count >= 100 ? '99+' : payload[0]?.count,
    });
    return;
  }

  if (counters[applicationKey]?.getCounter) {
    await counters[applicationKey].getCounter({
      onSuccess: ({ data }) => {
        commit('GENERIC_MUTATION', {
          property: 'counter',
          payload: data >= 100 ? '99+' : data,
        });
      },
    });
  }
};

export default { loadCounter };
