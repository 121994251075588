import Vue from 'vue';
import Vuex from 'vuex';
import counter from './counter';
import user from './user';
import client from './client';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: { counter, user, client },
});
