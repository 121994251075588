const applicationsAvailable =
  ({ clientApplications }) =>
  (actualApplicationKey = '') => {
    if (actualApplicationKey && actualApplicationKey.length > 0) {
      return clientApplications.filter((item) => item.key !== actualApplicationKey);
    }

    return clientApplications;
  };

export default { applicationsAvailable };
