import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import vuetify from '@/plugins/vuetify';
import toast from '@/plugins/toast';
import { getUserState, SocketIoClientPlugin, UserPlugin } from '@zitrus/utils-service';
import { notificacoesWebsocketURI } from '@/api';
import App from './App.vue';
import router from './router';
import axios from './services/instanceAxios';
import store from './store';

Vue.use(router);
Vue.use(UserPlugin);
Vue.use(toast);
Vue.use(SocketIoClientPlugin, {
  url: notificacoesWebsocketURI,
  configValues: {
    auth: { user: getUserState()?.email },
  },
});

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
