<template>
  <div id="z-header">
    <router-view />
    <session-change-dialog />
    <z-toast />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { listenEvent, removeEventListener } from '@/events/custom-events-service';
import SessionChangeDialog from '@/components/session-change-dialog.vue';
import ZToast from '@/components/z-toast.vue';

export default {
  name: 'App',
  components: { SessionChangeDialog, ZToast },
  created() {
    listenEvent('@zitrus/z-login/user-change-client', this.onUserClientChange);

    this.configureWebSocket();
  },
  beforeMount() {
    if (this.getLoggedUser()?.userId) {
      this.loadUserApplications({ userId: this.getLoggedUser()?.userId });
    }
  },
  beforeDestroy() {
    removeEventListener('@zitrus/z-login/user-change-client', this.onUserClientChange, false);

    this.$socket.off('workspace:user-session');
  },
  mounted() {
    if (this.getLoggedUser()?.clientId) {
      this.loadClientApplications({ clientId: this.getLoggedUser()?.clientId });
    }
  },
  methods: {
    ...mapActions('user', [
      'loadUserApplications',
      'loadLoggedUserApplications',
      'loadLoggedUserClients',
      'changeShowSessionDialog',
    ]),
    ...mapActions('client', ['loadClientApplications']),
    configureWebSocket() {
      this.$socket.on('workspace:user-session', this.onNewZNotificationEvent);
    },
    onNewZNotificationEvent(payload) {
      const [event] = payload;

      console.log('🚧 incoming event:', event);

      if (this.hasToShowSessionDialog(event)) {
        this.changeShowSessionDialog({ value: true });
        console.log('🚧 Trigger dialog to show');
      }
    },
    hasToShowSessionDialog(event) {
      if (this.ignoreEvent(event)) {
        return false;
      }

      console.log('🚧 event.clientId', event?.clientId);
      console.log('🚧 event.uuidToken', event?.uuidToken);

      return event?.clientId != null || event?.uuidToken != null;
    },
    ignoreEvent(event) {
      const currentTime = new Date();
      const eventTime = new Date(event?.dateTime || '');
      const secondsDiff = (currentTime.getTime() - eventTime.getTime()) / 1000;

      console.log('🚧 /selecionar-empresa ?', this.$route.path.includes('selecionar-empresa'));
      console.log('🚧 /login ?', this.$route.path.includes('login'));
      console.log(`🚧 secondsDiff (${secondsDiff}) ?`, secondsDiff > 1);

      return (
        this.$route.path.includes('selecionar-empresa') ||
        this.$route.path.includes('login') ||
        secondsDiff > 1
      );
    },
    onUserClientChange() {
      if (this.getLoggedUser()?.clientId) {
        this.loadClientApplications({ clientId: this.getLoggedUser()?.clientId });
      }
    },
  },
};
</script>

<style lang="scss">
@import './styles/global.scss';
</style>
